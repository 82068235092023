<template>
    <div>
        <div class="container-flex">
            <BaseButton
                :view="getViewByMode('uploaded')"
                form="square"
                style="margin-left: 20px;"
                @click="onUploaded"
            >
                Добавлено ({{summaryUploaded}})
            </BaseButton>

            <BaseButton
                :view="getViewByMode('withoutPrices')"
                form="square"
                style="margin-left: 20px;"
                @click="onWithoutPrices"
            >
                Без цен ({{summaryWithoutPrices}})
            </BaseButton>

            <BaseButton
                :view="getViewByMode('doubles')"
                form="square"
                style="margin-left: 20px;"
                @click="onDoubles"
            >
                Дубли ({{summaryDoubles}})
            </BaseButton>

            <BaseButton
                :view="getViewByMode('errors')"
                form="square"
                style="margin-left: 20px;"
                @click="onErrors"
            >
                Ошибки ({{summaryErrors}})
            </BaseButton>
        </div>

        <AppTable
            primaryKey="id"
            :columns="columns"
            :rows="bufferRows"
            class="cloud-import-log__table"
            @lazyload="onLazyload"
        >
            <template v-slot:tax="{row}">
                {{row.tax ? 'с НДС' : 'без НДС'}}
            </template>
            <template v-slot:light="{row}">
                {{row.light ? 'Да' : 'Нет'}}
            </template>
            <template v-slot:links="{row}">
                <div>
                    <a
                        v-if="row.image_src"
                        :href="row.image_src"
                        target="_blank"
                    >
                        Фото
                    </a>
                    <a
                        v-if="row.map_link"
                        :href="row.map_link"
                        target="_blank"
                    >
                        Карта
                    </a>
                </div>
            </template>
            <template v-slot:report="scopeData">
                <div
                    v-if="mode === 'uploaded'"
                    class="cloud-import-log__dates"
                >
                    <p
                        v-for="(price, index) in scopeData.row.prices"
                        :key="index"
                        :style="{color: '#' + getMonthColor(price)}"
                    >
                        {{getMonthLabel(price)}}
                    </p>
                </div>

                <div
                    v-if="mode === 'doubles'"
                    class="cloud-import-log__dates"
                >
                    Дубль строки '{{scopeData.row.double_row}}'
                </div>

                <div
                    v-if="mode === 'errors'"
                    class="cloud-import-log__errors"
                >
                    <div
                        v-for="(error, index) in scopeData.row.errors"
                        :key="index"
                    >
                        {{error}}
                    </div>
                </div>
            </template>
        </AppTable>
    </div>
</template>

<script>
import SchemeCloudImportLog from "@/schemes/SchemeCloudImportLog";
const {columns: schemeColumns = []} = SchemeCloudImportLog;

import BaseButton from "@/components/Base/BaseButton.vue";
import AppTable from '@/components/Table2/Table.vue';
import UtilDate from "@/utils/UtilDate";

export default {
    name: "CloudImportLog",
    components: {
        BaseButton,
        AppTable
    },
    data: () => ({
        summaryUploaded: 0,
        summaryWithoutPrices: 0,
        summaryDoubles: 0,
        summaryErrors: 0,
        columns: schemeColumns,
        bufferRows: []
    }),
    props: {
        mode: {
            type: String,
            default: () => ('uploaded')
        },
        rows: {
            type: Array,
            default: () => ([])
        },
        isStaticSummary: {
            type: Boolean,
            default: false
        },
        summary: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {

    },
    watch: {
        rows() {
            this.createBuffer();
        },

        mode() {
            this.createBuffer();
        }
    },
    mounted() {
        if(this.isStaticSummary){
            const {uploaded = 0, without_prices = 0, doubles = 0, errors = 0} = this.summary
            this.summaryUploaded = uploaded
            this.summaryWithoutPrices = without_prices
            this.summaryDoubles = doubles
            this.summaryErrors = errors
        }

        this.createBuffer();
    },
    methods: {
        onUploaded() {
            this.$emit('onChangeMode', 'uploaded');
        },

        onWithoutPrices() {
            this.$emit('onChangeMode', 'withoutPrices');
        },

        onDoubles() {
            this.$emit('onChangeMode', 'doubles');
        },

        onErrors() {
            this.$emit('onChangeMode', 'errors');
        },

        createBuffer() {
            if(!this.isStaticSummary){
                this.summaryUploaded = 0
                this.summaryWithoutPrices = 0
                this.summaryDoubles = 0
                this.summaryErrors = 0
            }

            this.bufferRows = this.rows.reduce((rows, row) => {
                const {errors = [], double_row: doubleRow = null, prices = {}} = row
                const isDouble = doubleRow !== null
                const isErrors = (errors ?? []).length > 0

                let isWithoutPrices = true;
                Object.values(prices ?? {}).forEach((price) => {
                    const {price: priceVal = null} = price
                    if(priceVal !== null && priceVal){
                        isWithoutPrices = false
                    }
                })

                const isUploaded = !isErrors && !isDouble && !isWithoutPrices;

                if(isUploaded && !this.isStaticSummary){
                    this.summaryUploaded++;
                }

                if(!isErrors && isDouble && !this.isStaticSummary){
                    this.summaryDoubles++;
                }

                if(isErrors && !this.isStaticSummary){
                    this.summaryErrors++;
                }

                if(!isErrors && isWithoutPrices && !this.isStaticSummary){
                    this.summaryWithoutPrices++;
                }

                switch (this.mode) {
                    case 'uploaded': {
                        if (isUploaded) {
                            rows.push(row);
                        }
                        break;
                    }
                    case 'withoutPrices': {
                        if (!isErrors && isWithoutPrices) {
                            rows.push(row);
                        }
                        break;
                    }
                    case 'doubles': {
                        if (!isErrors && doubleRow !== null) {
                            rows.push(row);
                        }
                        break;
                    }
                    case 'errors': {
                        if (isErrors) {
                            rows.push(row);
                        }
                        break;
                    }
                }

                return rows;
            }, [])
        },

        getViewByMode(mode) {
            return this.mode === mode ? 'third' : 'secondary';
        },

        getMonthLabel(price) {
            const {date = null} = price
            return UtilDate.getMonthsName(date)
        },

        getMonthColor(price) {
            const {action = null} = price
            switch (action) {
                case 'insert':
                    return '008e13'
                case 'update':
                    return 'f1c232'
                case 'delete':
                    return 'e06666'
            }
            return '000000'
        },

        onLazyload(){
            console.log("lazyload")
            this.$emit('onLazyload')
        }
    }
}
</script>

<style scoped lang="scss">
.cloud-import-log {
    &__table {
        width: 100%;
        height: auto;
        max-height: 100vh;
    }

    &__dates {
        display: flex;
        overflow-x: auto;
    }

    &__errors {
        display: block;
    }

    &__dates p{
        padding: 2px;
    }
}

.container-flex {
    display: flex;
    padding: 10px;

    .base-checkbox{
        margin: 5px;
    }
}
</style>
